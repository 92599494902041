import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import "react-input-range/lib/css/index.css"
import Masonry from 'react-masonry-component'
import CreateBoardModal from "../../ContentBuilder/Modal/CreateBoardModal"
import { getUser, isLoggedIn } from "../../../services/auth"
import QuickViewModalData from "../../ContentBuilder/Modal/QuickViewModalData"
import { connect } from "react-redux"
import { doFilter, filterVariant } from "../../../services/filter"
import config from "../../../config/config"

const AllList = ({ activeFilters, currentBoards, sort, currentSearch }) => {
  return <AllItems activeFilters={activeFilters} currentBoards={currentBoards} sort={sort} currentSearch={currentSearch} />
}

const mapStateToProps = (state) => ({
  activeFilters: state.filter.active,
  currentBoards: state.board.all,
  sort: state.sort.current,
  currentSearch: state.search.current
})

export default connect(mapStateToProps, null)(AllList)

function AllItems({ activeFilters, currentBoards, sort, currentSearch }) {
  const loadPage = () => {
    setPageLimit(pageLimit + 12)
    if (pageLimit + 12 >= tiles.length) {
      setHasMore(false)
    }
  }
  const allItems = useStaticQuery(graphql`
    query MyQuery {
        silverStripeDataObject(className: {eq: "Undigital__Objects__GlobalConfig"}) {
            UndigitalGlobalConfig {
              hidePricingGlobally
            }
        }
      allSilverStripeDataObject(filter: {link: {eq: null}, className: {in: [
"Undigital__Objects__HomeDesign", 
"Undigital__Objects__Cabinetry", 
"Undigital__Objects__Furniture", 
"Undigital__Objects__Selection", 
"Undigital__Objects__Facade", 
"Undigital__Objects__FinishesFixtures"]}}) {
        nodes {
          UndigitalHomeDesign {
            name
            objectID
            showTitleOnTile
            tileBlackTitle
            imagePosition
            description            
            homeSize            
            Storeys {
              UndigitalStoreys {
                name
              }
            }
            FeaturedFloorPlan {
              UndigitalFloorplan {
                name
                objectID
                bath
                bed
                garage
                living                
                firstFloorImagePath
                groundFloorImagePath
              }
            }
            urlSegment
            status
            imagePath
            homeTypeID
            storeysID
          }
          UndigitalCabinetry {
            name
            objectID
            showTitleOnTile
            tileBlackTitle
            urlSegment
            status            
            description
            Variations {
              UndigitalCabinetryVariation {
                featureImagePath
                name
                objectID
                status
                rrPrice    
                Styles {
                  UndigitalProductStyle {
                    objectID
                    name
                  }
                }
              }
            }
            FeaturedVariation {
              UndigitalCabinetryVariation {
                name
                objectID
                rrPrice
                imagePosition
                featureImagePath                
                Styles {
                  UndigitalProductStyle {
                    name
                  }
                }
              }
            }
          }
          UndigitalFurniture {
            name
            objectID
            showTitleOnTile
            tileBlackTitle
            urlSegment
            about
            status            
            Variations {
              UndigitalFurnitureVariation {
                status
                name
                objectID
                rrp
                productImagePath
                styleID
                colourID
              }
            }
            FeaturedVariation {
              UndigitalFurnitureVariation {
                name
                objectID
                productImagePath
                imagePosition
                rrp          
                Style {
                  UndigitalProductStyle {
                    name
                  }
                }
              }
            }
            brandID
          }
          UndigitalSelection {
            name
            objectID
            showTitleOnTile
            tileBlackTitle
            urlSegment
            status
            imagePosition
            imagePath
            about
            Items {
              UndigitalSelectionItem {
                name
                objectID
                status
                productImagePath
              }
            }
            Style {
              UndigitalProductStyle {
                name
              }
            }
            styleID
          }
          UndigitalFacade {
            name
            objectID
            showTitleOnTile
            tileBlackTitle
            urlSegment
            about
            status
            Variations {
              UndigitalFacadeVariation {
                name
                objectID
                featureImagePath
                imagePosition
                status
                rrPrice
                Storeys {
                  UndigitalStoreys {
                    objectID
                    name
                  }
                }
              }
            }
            FeaturedVariation {
              UndigitalFacadeVariation {                
                name
                objectID
                rrPrice
                featureImagePath
                imagePosition
                Storeys {
                  UndigitalStoreys {
                    objectID
                    name
                  }
                }
              }
            }
          }
          UndigitalFinishesFixtures {
            name
            objectID
            showTitleOnTile
            tileBlackTitle
            status
            urlSegment
            about            
            Variations {
              UndigitalFinishesFixturesVariation {
                name
                objectID
                featureProductImagePath
                status
                rrp
                colourID
              }
            }
            FeaturedVariation {
              UndigitalFinishesFixturesVariation {
                name
                objectID
                rrp
                featureProductImagePath
                imagePosition
                colourID
              }
            }
            brandID
          }
        }
      }
    }    
  `)
  const host = config.host
  const [pageLimit, setPageLimit] = useState(15)
  const [hasMore, setHasMore] = useState(true)
  var items = []
  const unfiltered_items = allItems.allSilverStripeDataObject.nodes
  const globalConfig = allItems.silverStripeDataObject.UndigitalGlobalConfig
  var i = 0
  const classes = ['grid-item', 'grid-item no-hover', 'grid-item large']
  const search = typeof window !== "undefined" ? window.location.search : "/"
  const params = new URLSearchParams(search)
  var keyword = params.get("keyword")
  var rrp
  var itemPrice
  for (; unfiltered_items[i];) {
    var item = unfiltered_items[i]
    if (item.UndigitalHomeDesign != null) {
      let object = item.UndigitalHomeDesign
      if (object.status === 'Active') {
        let image_path = null
        var FeaturedFloorPlan = object.FeaturedFloorPlan
        if (FeaturedFloorPlan !== null) {
          if (FeaturedFloorPlan.UndigitalFloorplan.groundFloorImagePath) {
            image_path = FeaturedFloorPlan.UndigitalFloorplan.groundFloorImagePath
          } else if (FeaturedFloorPlan.UndigitalFloorplan.firstFloorImagePath) {
            image_path = object.UndigitalFloorplan.firstFloorImagePath
          }
        }
        if(image_path === null){
            image_path = object.imagePath
        }
        object.Image = image_path

        object.link = '/explore/home-designs/' + object.urlSegment
        object.grid = classes[0]
        object.className = 'HomeDesign'
        if (object.Storeys != null) {
          object.bottomText = object.Storeys.UndigitalStoreys.name
        }
        if (object.Floorplans) {
          object.bed = []
          object.bath = []
          object.garage = []
          object.living = []
          object.roomAmenities = []
          for (const it of object.Floorplans) {
            const fp = it.UndigitalFloorplan
            if (fp.bed && !object.bed.includes(fp.bed)) object.bed.push(fp.bed)
            if (fp.bath && !object.bath.includes(fp.bath)) object.bath.push(fp.bath)
            if (fp.garage && !object.garage.includes(fp.garage)) object.garage.push(fp.garage)
            if (fp.living && !object.living.includes(fp.living)) object.living.push(fp.living)

            if (fp.GroundFloorRoomsAmenities) {
              for (const ite of fp.GroundFloorRoomsAmenities) {
                const ra = ite.UndigitalRoomAmenity
                if (!object.roomAmenities.some(t => t === ra.objectID)) object.roomAmenities.push(ra.objectID)
              }
            }

            if (fp.Level1RoomsAmenities) {
              for (const ite of fp.Level1RoomsAmenities) {
                const ra = ite.UndigitalRoomAmenity
                if (!object.roomAmenities.some(t => t === ra.objectID)) object.roomAmenities.push(ra.objectID)
              }
            }
          }
        }
        if (object.homeTypeID) object.homeTypes = [object.homeTypeID]
        if (object.storeysID) object.storeys = [object.storeysID]
        let match = true
        if (keyword) {
          keyword = keyword.toLowerCase().trim().replace(/  +/g, ' ')
          let keyword_arr = keyword.split(" ")
          let _name = object.name.toLowerCase()
          if (!keyword_arr.some(v => _name.includes(v))) {
            match = false
          }
        }
        if (match) {
          items.push(object)
        }
      }
    } else if (item.UndigitalCabinetry != null) {
      let object = item.UndigitalCabinetry
      object.about = object.description
      if (object.status === 'Active') {
        object.link = '/explore/cabinetry/' + object.urlSegment
        let stylesString = ""
        rrp = 0
        if (object.FeaturedVariation !== null) {
          var CabinetryfeaturedVariation = object.FeaturedVariation
          if (CabinetryfeaturedVariation.UndigitalCabinetryVariation.Styles !== null) {
            const styles = CabinetryfeaturedVariation.UndigitalCabinetryVariation.Styles
            let stylesList = []
            var j = 0
            for (; styles[j];) {
              stylesList[j] = styles[j].UndigitalProductStyle.name
              j++
            }
            stylesString = stylesList.join(", ")
          }
          object.imagePath = CabinetryfeaturedVariation.UndigitalCabinetryVariation.featureImagePath
          object.Image = CabinetryfeaturedVariation.UndigitalCabinetryVariation.featureImagePath
          rrp = CabinetryfeaturedVariation.UndigitalCabinetryVariation.rrPrice
          object.imagePosition = CabinetryfeaturedVariation.UndigitalCabinetryVariation.imagePosition
        }

        itemPrice = parseFloat(
          Math.round(rrp * 1000) / 1000
        ).toFixed(2)
        object.rrp = "$" + itemPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        object.bottomPrice = "Price: " + object.rrp
        object.styles = stylesString
        object.grid = classes[0]
        object.className = 'Cabinetry'

        if (object.Variations) {
          object.styleArray = []
          object.colorArray = []
          for (const i of object.Variations) {
            const variant = i.UndigitalCabinetryVariation
            if (variant.Styles) {
              for (const st of variant.Styles) {
                const style = st.UndigitalProductStyle
                if (!object.styleArray.some(sa => sa === style.objectID)) object.styleArray.push(style.objectID)
              }
            }

            if (variant.Colours) {
              for (const cl of variant.Colours) {
                const color = cl.UndigitalColour
                if (!object.colorArray.some(sa => sa === color.objectID)) object.colorArray.push(color.objectID)
              }
            }
          }
        }

        var FeaturedVariation = object.FeaturedVariation
        object.variantTiles = object.Variations ? object.Variations.map(v => {
          return {
            ...v.UndigitalCabinetryVariation,
            featured: v.objectID == FeaturedVariation.UndigitalCabinetryVariation.objectID
          }
        }).filter(v => v.status === 'Active').sort((i1, i2) => {
          if (i1.objectID == FeaturedVariation.UndigitalCabinetryVariation.objectID) return -1
          if (i2.objectID == FeaturedVariation.UndigitalCabinetryVariation.objectID) return 1
          return 0
        }) : []

        let match = true
        if (keyword) {
          keyword = keyword.toLowerCase().trim().replace(/  +/g, ' ')
          let keyword_arr = keyword.split(" ")
          let _name = object.name.toLowerCase()
          if (!keyword_arr.some(v => _name.includes(v))) {
            match = false
          }
        }
        if (match) {
          items.push(object)
        }
      }
    } else if (item.UndigitalFurniture != null) {
      let object = item.UndigitalFurniture
      if (object.status === 'Active') {
        object.link = '/explore/furniture/' + object.urlSegment + '/'
        let stylesString = ""
        rrp = 0
        if (object.FeaturedVariation !== null) {
          var featuredVariationFurniture = object.FeaturedVariation
          if (featuredVariationFurniture.UndigitalFurnitureVariation.Style !== null) {
            stylesString = featuredVariationFurniture.UndigitalFurnitureVariation.Style.UndigitalProductStyle.name
          }
          rrp = featuredVariationFurniture.UndigitalFurnitureVariation.rrp
          object.imagePath = featuredVariationFurniture.UndigitalFurnitureVariation.productImagePath
          object.Image = featuredVariationFurniture.UndigitalFurnitureVariation.productImagePath
          object.imagePosition = featuredVariationFurniture.UndigitalFurnitureVariation.imagePosition
        }
        itemPrice = parseFloat(
          Math.round(rrp * 1000) / 1000
        ).toFixed(2)
        object.rrp = "$" + itemPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        object.bottomPrice = "Price: " + object.rrp
        object.styles = stylesString
        object.grid = classes[0]
        object.className = 'Furniture'

        if (object.brandID) object.brands = [object.brandID]
        if (object.Variations) {
          for (const i of object.Variations) {
            const variant = i.UndigitalFurnitureVariation
            if (variant.styleID) object.styleArray = [variant.styleID]
            if (variant.colourID) object.colorArray = [variant.colourID]
          }
        }
        var FeaturedVariation = object.FeaturedVariation
        object.variantTiles = object.Variations ? object.Variations.map(v => {
          return {
            ...v.UndigitalFurnitureVariation,
            featured: v.objectID == FeaturedVariation.UndigitalFurnitureVariation.objectID
          }
        }).filter(v => v.status === 'Active').sort((i1, i2) => {
          if (i1.objectID == FeaturedVariation.UndigitalFurnitureVariation.objectID) return -1
          if (i2.objectID == FeaturedVariation.UndigitalFurnitureVariation.objectID) return 1
          return 0
        }) : []

        let match = true
        if (keyword) {
          keyword = keyword.toLowerCase().trim().replace(/  +/g, ' ')
          let keyword_arr = keyword.split(" ")
          let _name = object.name.toLowerCase()
          if (!keyword_arr.some(v => _name.includes(v))) {
            match = false
          }
        }
        if (match) {
          items.push(object)
        }
      }
    } else if (item.UndigitalSelection != null) {
      let object = item.UndigitalSelection
      if (object.status === 'Active') {
        let stylesString = ""
        object.link = '/explore/style/' + object.urlSegment + '/'
        if (object.Style !== null) {
          stylesString = object.Style.UndigitalProductStyle.name
        }
        object.styles = stylesString
        object.grid = classes[0]
        object.className = 'Selection'

        if (object.styleID) object.styleArray = [object.styleID]

        let match = true
        if (keyword) {
          keyword = keyword.toLowerCase().trim().replace(/  +/g, ' ')
          let keyword_arr = keyword.split(" ")
          let _name = object.name.toLowerCase()
          if (!keyword_arr.some(v => _name.includes(v))) {
            match = false
          }
        }
        object.Image = object.imagePath

        if (match) {
          items.push(object)
        }
      }
    } else if (item.UndigitalFacade != null) {
      let object = item.UndigitalFacade
      if (object.status === 'Active') {
        object.link = '/explore/facades/' + object.urlSegment + '/'
        let stylesString = ""
        rrp = 0
        if (object.FeaturedVariation !== null) {
          var featuredVariationFacade = object.FeaturedVariation
          if (featuredVariationFacade.UndigitalFacadeVariation.Styles !== null) {
            const styles = featuredVariationFacade.UndigitalFacadeVariation.Styles
          }
          rrp = featuredVariationFacade.UndigitalFacadeVariation.rrPrice
          object.imagePath = featuredVariationFacade.UndigitalFacadeVariation.featureImagePath
          object.imagePosition = featuredVariationFacade.UndigitalFacadeVariation.imagePosition
        }

        var FeaturedVariation = object.FeaturedVariation
        object.variantTiles = object.Variations ? object.Variations.map(v => {
          return {
            ...v.UndigitalFacadeVariation,
            featured: v.objectID == FeaturedVariation.UndigitalFacadeVariation.objectID
          }
        }).filter(v => v.status === 'Active').sort((i1, i2) => {
          if (i1.objectID == FeaturedVariation.UndigitalFacadeVariation.objectID) return -1
          if (i2.objectID == FeaturedVariation.UndigitalFacadeVariation.objectID) return 1
          return 0
        }) : []

        let image_path = null
        if (FeaturedVariation && FeaturedVariation.UndigitalFacadeVariation.featureImagePath) {
          image_path = FeaturedVariation.UndigitalFacadeVariation.featureImagePath
        }
        object.className = 'Facade'
        object.Image = image_path

        itemPrice = parseFloat(
          Math.round(rrp * 1000) / 1000
        ).toFixed(2)
        object.rrp = "$" + itemPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        object.bottomPrice = "Price: " + object.rrp
        object.styles = stylesString
        object.grid = classes[0]

        if (object.Variations) {
          for (const i of object.Variations) {
            const variant = i.UndigitalFacadeVariation
            if (variant.Styles) {
              object.styleArray = []
              for (const i of variant.Styles) {
                const style = i.UndigitalProductStyle
                if (!object.styleArray.some(sa => sa === style.objectID)) object.styleArray.push(style.objectID)
              }
            }
            if (variant.storeysID) object.storeys = [variant.storeysID]
          }
        }

        let match = true
        if (keyword) {
          keyword = keyword.toLowerCase().trim().replace(/  +/g, ' ')
          let keyword_arr = keyword.split(" ")
          let _name = object.name.toLowerCase()
          if (!keyword_arr.some(v => _name.includes(v))) {
            match = false
          }
        }
        if (match) {
          items.push(object)
        }
      }
    } else if (item.UndigitalFinishesFixtures != null) {
      let object = item.UndigitalFinishesFixtures
      if (object.status === 'Active') {
        object.link = '/explore/finishes-fixtures/' + object.urlSegment + '/'
        let stylesString = ""
        rrp = 0
        let image_path = null
        if (object.FeaturedVariation !== null) {
          var featuredVariationFixture = object.FeaturedVariation
          rrp = featuredVariationFixture.UndigitalFinishesFixturesVariation.rrp
          object.imagePath = featuredVariationFixture.UndigitalFinishesFixturesVariation.featureProductImagePath
          object.imagePosition = featuredVariationFixture.UndigitalFinishesFixturesVariation.imagePosition
          image_path = featuredVariationFixture.UndigitalFinishesFixturesVariation.featureProductImagePath
        }
        object.Image = image_path
        itemPrice = parseFloat(
          Math.round(rrp * 1000) / 1000
        ).toFixed(2)
        object.rrp = "$" + itemPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        object.bottomPrice = "Price: " + object.rrp
        object.styles = stylesString
        object.grid = classes[0]
        object.className = 'FinishesFixtures'

        if (object.brandID) object.brands = [object.brandID]
        if (object.Variations) {
          for (const i of object.Variations) {
            const variant = i.UndigitalFinishesFixturesVariation
            if (variant.styleID) object.styleArray = [variant.styleID]
            if (variant.colourID) object.colorArray = [variant.colourID]
          }
        }
        var FeaturedVariation = object.FeaturedVariation
        object.variantTiles = object.Variations ? object.Variations.map(v => {
          return {
            ...v.UndigitalFinishesFixturesVariation,
            featured: v.objectID == FeaturedVariation.UndigitalFinishesFixturesVariation.objectID
          }
        }).filter(v => v.status === 'Active').sort((i1, i2) => {
          if (i1.objectID == FeaturedVariation.UndigitalFinishesFixturesVariation.objectID) return -1
          if (i2.objectID == FeaturedVariation.UndigitalFinishesFixturesVariation.objectID) return 1
          return 0
        }) : []
        let match = true
        if (keyword) {
          keyword = keyword.toLowerCase().trim().replace(/  +/g, ' ')
          let keyword_arr = keyword.split(" ")
          let _name = object.name.toLowerCase()
          if (!keyword_arr.some(v => _name.includes(v))) {
            match = false
          }
        }
        if (match) {
          items.push(object)
        }
      }
    }
    i++
  }

  // do filter
  if (activeFilters.length) items = doFilter(activeFilters, items)

  // search
  if (currentSearch.length) {
    items = items.filter(it => it.name.toLowerCase().includes(currentSearch.toLowerCase()))
  }

  items.sort((a, b) => {
    if (sort === "az") {
      return a.name > b.name ? 1 : -1
    } else if (sort === "latest") {
      return Number(a.objectID) < Number(b.objectID) ? 1 : -1
    } else {
      return Number(a.objectID) < Number(b.objectID) ? 1 : -1
    }
  })

  const currentData = items

  const [showCreateBoard, setShowCreateBoard] = useState(false)
  const [objectClass, setObjectClass] = useState()
  const [objectID, setObjectID] = useState()
  const showAddBoard = (className, objectID) => {
    if (isLoggedIn()) {
      setObjectClass(className)
      setObjectID(objectID)
      setShowCreateBoard(true)
    } else {
      if (typeof window !== 'undefined') {
        window.location.href = "/login/"
      }
    }
  }

  const [tiles, setTiles] = useState([])
  useEffect(() => {
    let renderTiles = []
    for (const [idx, item] of currentData.entries()) {
      let isSaved = false

      if (item.variantTiles && item.variantTiles.length) {
        for (const [index, vt] of item.variantTiles.entries()) {
          isSaved = false
          if (typeof currentBoards !== 'undefined') {
            for (const board of currentBoards) {
              const items = JSON.parse(board.Items)
              for (const key in items) {
                if (key.includes(item.className) && items[key].ID == item.objectID && items[key].VariantID == vt.objectID) {
                  isSaved = true
                }
              }
            }
          }
          if (activeFilters.length && !filterVariant(activeFilters, vt)) continue
          let ip = vt.rrPrice || 0
          ip = parseFloat(
            Math.round(ip * 1000) / 1000
          ).toFixed(2)
          let formattedPrice = "$" + ip.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          let vt_image = null
          if (vt.featureImagePath) {
            vt_image = vt.featureImagePath
          } else if (vt.productImagePath) {
            vt_image = vt.productImagePath
          }else if (vt.featureProductImagePath) {
            vt_image = vt.featureProductImagePath
          }
          var _id = item.objectID + vt.objectID
          vt.Image = vt_image
          renderTiles.push({
            objectID: item.objectID,
            id: _id,
            tilename: item.name,
            featuredRRPrice: vt.rrPrice,
            featured: vt.featured,
            item: { ...item },
            vt: { ...vt },
            tileImageSrc: vt.Image,
            formattedPrice,
            isSaved,
          })
        }
      } else {
        if (typeof currentBoards !== 'undefined') {
          for (const board of currentBoards) {
            const items = JSON.parse(board.Items)
            for (const key in items) {
              if (key.includes(item.className) && items[key].ID == item.objectID) {
                isSaved = true
              }
            }
          }
        }
        renderTiles.push({
          objectID: item.objectID,
          id: item.objectID,
          tilename: item.name,
          featuredRRPrice: item.featuredRRPrice,
          featured: false,
          item: { ...item },
          vt: null,
          tileImageSrc: item.Image,
          formattedPrice: item.formattedPrice,
          isSaved,
        })
      }
    }

    renderTiles.sort((i1, i2) => {
      if (i1.featured && !i2.featured) return -1
      if (!i1.featured && i2.featured) return 1
      return 0
    })

    renderTiles.sort((a, b) => {
      if (sort === "az") {
        return a.tilename > b.tilename ? 1 : -1
      } else if (sort === "latest") {
        return Number(a.objectID) < Number(b.objectID) ? 1 : -1
      } else if (sort === "pricehightolow") {
        return a.featuredRRPrice < b.featuredRRPrice ? 1 : -1
      } else if (sort === "pricelowtohigh") {
        return a.featuredRRPrice > b.featuredRRPrice ? 1 : -1
      } else {
        return Number(a.objectID) < Number(b.objectID) ? 1 : -1
      }
    })

    setTiles(renderTiles)
  }, [activeFilters, currentBoards, sort, currentSearch])

  const renderTileGrid = useMemo(() => {
    if (tiles.length == 0 || (tiles.length > 0 && tiles.length === tiles.slice(0, pageLimit).length)) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
    return tiles.slice(0, pageLimit).map((tile, idx) => (
      <div key={idx} className={`grid-item ${tile.item.showTitleOnTile == 1 ? ' showtitle' : ''} ${tile.item.tileBlackTitle == 1 ? 'text-black' : ''} ${tile.item.objectID} ${tile.item.imagePath ? '' : ' no-image'}`}>
        <button
          type="button"
          className="btn-like"
          onClick={() => showAddBoard(tile.item.className, tile.item.objectID)}
        >
          {tile.isSaved ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
              <path id="Path_878" data-name="Path 878" d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z" transform="translate(21.982 6.975)" fill="#fff" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" />
            </svg>
          ) : <svg id="icon-heart" width="35" height="35" viewBox="0 0 35 35"><path d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z" transform="translate(21.982 6.975)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5"></path></svg>}
        </button>
        <a onClick={e => setUpQuickViewModal(e, tile.item, tile.vt)} href={tile.item.link} className={`grid-item-inner ${tile.item.imagePosition}`}>
          {tile.tileImageSrc && (
            <img src={tile.tileImageSrc} alt="" />
          )}
          <div className="grid-caption">
            <h4>{tile.tilename}</h4>
            {(tile.vt && tile.vt.name) && (
            <div className="grid-bottom">              
                <p className="variant-name">{tile.vt.name}</p>              
            </div>
            )}
          </div>
        </a>
      </div>
    ))
  }, [tiles, pageLimit])

  const [showQuickView, setShowQuickView] = useState(false)
  const [quickViewData, setQuickViewData] = useState(null)
  const setUpQuickViewModal = useCallback((e, item, vt) => {
    e.preventDefault()
    item.vt = vt
    setQuickViewData(item)
    setShowQuickView(true)
  }, [])

  const Member = getUser()
  let boards = []
  if (isLoggedIn()) {
    if (typeof Member.Boards !== 'undefined')
      boards = Object.keys(Member.Boards).map(key => Member.Boards[key])
  }

  return (
    <>
      <Masonry className="grid"
        options={{ columnWidth: '.grid-sizer', transitionDuration: 0, percentPosition: true }}
      >
        <div className="grid-sizer"></div>
        {renderTileGrid}
      </Masonry>
      {hasMore && (
        <div className="text-center">
          <button
            onClick={loadPage}
            className="btn btn-black btn-lg mt-5"
          >
            LOAD MORE
          </button>
        </div>
      )}
      <CreateBoardModal
        show={showCreateBoard}
        handleClose={() => setShowCreateBoard(false)}
        objectClass={objectClass}
        objectID={objectID} />
      {quickViewData !== null && (
        <QuickViewModalData
          show={showQuickView}
          handleClose={() => setShowQuickView(false)}
          item={quickViewData}
          hidePricingGlobally={globalConfig.hidePricingGlobally}
        />
      )}
    </>
  )
}
